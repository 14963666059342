import { addParamsToUrl } from '~/shared/utils/url';

export const defaultMediaWidths = [
  300,
  400,
  600,
  800,
  1000,
  1200,
  1600,
  2400,
];

export const generateResizedMediaUrl = ({
  mediaUrl, width, height = '',
}) => addParamsToUrl(mediaUrl, {
  resize: `${width}x${height}`,
  vertical: 'center',
});

export const generateRiasUrl = ({ mediaUrl, appendHeight = false }) => (
  decodeURIComponent(addParamsToUrl(mediaUrl, {
    resize: appendHeight ? '{width}x{height}' : '{width}x',
    vertical: 'center',
  }))
);

export const generateSrcset = ({
  mediaUrl, widths, aspectRatio,
}) => {
  if (!mediaUrl) return '';

  return widths.map((width) => {
    const height = Math.round(width / aspectRatio);
    const url = generateResizedMediaUrl({
      mediaUrl, width, height,
    });

    return `${url} ${width}w`;
  }).join(', ');
};
